import React from "react";
import { Link, navigate } from "@reach/router";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import Config from "../config";
import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import TrialBlock from "../components/blocks/TrialBlock";
import TemplateOptions from "../components/menus/TemplateOptions";
import { SparklesIcon } from "@heroicons/react/24/outline";

class TemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      deals: [],
      selected: [],
      runResetDate: null
    };
    this._isMounted = false;
  }

  componentDidMount = async () => {
    document.title = `Templates ${Config.titleSuffix}`;
    this._isMounted = true;

    const user = await Session.getUserData();
    if(!user) {
      Session.logout(this.props.location.pathname);
      return;
    }
    if (this._isMounted) {
      await this.getStatus();
      await this.getAccountStatus();

      const periodStartDate = DateTime.fromISO(user.org.periodStartDate);
      const runResetDate = periodStartDate.plus({ months: 1 }).toFormat("d LLLL");
      this.setState({ runResetDate });
    }

    this.setState({ pageLoading: false, user });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getAccountStatus() {
    try {
      const requestUrl = Config.api.root + "/admin/org/account";
      const response = await fetch(requestUrl, Config.api.options);

      if (response.status !== 200) {
        throw response.status;
      }

      const json = await response.json();

      if (json.error) {
        throw json.error;
      }

      this.setState({
        account: {
          runCount: Number(json.runCount),
          runLimit: Number(json.runLimit),
          templateLimit: Number(json.templateLimit),
          features: json.features
        }
      });
    } catch (error) {
      console.log("error", error);
      this._isMounted && this.setState({ pageLoading: false });
    }
  }

  async getStatus() {
    try {
      let requestUrl = Config.api.root + "/template/list";
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      this._isMounted &&
        this.setState({
          ...response,
        });
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      this._isMounted && this.setState({ pageLoading: false });
    }
  }

  async createTemplate(e) {
    try {
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/template/create";
      let options = {
        method: "POST",
        body: JSON.stringify({
          name: this.state.newTemplateName
            ? this.state.newTemplateName
            : `Template ${this.state.templates.length + 1}`,
        }),
      };

      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });

      if (response.status !== 200) {
        throw response.status;
      }

      response = await response.json();

      if (response.error) {
        throw response.error;
      }

      navigate("/template/" + response.id);
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Template Creation Failed",
          message:
            typeof error === "string"
              ? error
              : "We were unable to create your template. Please try again.",
        },
      });
    }
  }

  async cloneTemplate(templateId) {
    try {
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/template/clone/" + templateId;
      let options = {
        method: "GET",
      };

      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });

      if (response.status !== 200) {
        throw response.status;
      }

      response = await response.json();

      if (response.error) {
        throw response.error;
      }

      navigate("/template/" + response.id);
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Template Clone Failed",
          message:
            typeof error === "string"
              ? error
              : "We were unable to clone your template. Please try again.",
        },
      });
    }
  }

  async deleteTemplate(templateId) {
    try {
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/template/delete/" + templateId;
      let options = {
        method: "DELETE",
      };
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      await this.getStatus();
      this.setState({ loading: false });
    } catch (error) {
      this.setState({
        error: true,
        errorDetails: { title: "Could not delete template", message: error },
        loading: false,
      });
    }
  }

  canCreateTemplate() {
    const count = this.state?.templates?.length;
    const limit = this.state?.account?.templateLimit;
    return count < limit;
  }

  render() {
    const templateListContainer =
      this.state.templates &&
      this.state.templates.map((template, index) => {
        return (
          <div className="flex px-5 py-5 mb-2 card" key={index}>
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-col flex-grow">
                <div className="flex flex-row items-center gap-5">
                  {template.active ? (
                    <span className="px-2 py-1 text-xs font-medium text-green-700 rounded-md bg-green-50 ring-green-600/20 ring-1 ring-inset">
                      Active
                    </span>
                  ) : (
                    <span className="px-2 py-1 text-xs font-medium rounded-md text-tone-700 bg-tone-400/20 ring-tone-700/20 ring-1 ring-inset">
                      Inactive
                    </span>
                  )}
                  <div className="flex-shrink">
                    <h3 className="text-lg font-semibold text-dark">{template.name}</h3>
                    <p className="flex flex-row gap-4 text-sm text-tone-700">
                      <span>
                        {template.template_lines_count} line item
                        {template.template_lines_count !== 1 && "s"}
                      </span>
                      <span>
                        Run {template.log_success} time{template.log_success !== 1 && "s"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center justify-end gap-3">
                <Link to={"/template/" + template.id} className="button-primary">
                  Edit
                </Link>
                <TemplateOptions
                  template={template}
                  cloneTemplate={() => this.cloneTemplate(template.id)}
                  cloneDisabled={!this.canCreateTemplate()}
                  deleteTemplate={() => this.deleteTemplate(template.id)}
                />
              </div>
            </div>
          </div>
        );
      });

    return (
      <>
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <>
            <motion.div
              key="1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-10 lg:mx-auto">
                <div className="flex-row flex-1 w-full">
                  <header className="relative flex flex-row pb-10 mb-5 border-b mt-36 border-tone-500">
                    <div className="flex flex-col items-baseline flex-grow gap-5">
                      <h1 className="">My Templates</h1>
                    </div>
                  </header>

                  <div className="relative my-10">
                    <div className="flex flex-col">{templateListContainer}</div>

                    <div className="absolute top-0 flex-col hidden gap-2 ml-16 lg:flex left-full h-min w-96">
                      {this.state?.user?.org?.plan === "trial" && (
                        <TrialBlock
                          trialExpiry={this.state?.user?.org?.trialExpiry}
                          runCount={this.state?.account?.runCount}
                          runLimit={this.state?.account?.runLimit}
                          showButton={true}
                        />
                      )}
                      {this.state.user?.org?.plan !== "trial" && this.state?.account?.runCount >= 0 && (
                        <>
                          <h3>Number of Runs</h3>
                          <p className="text-4xl">
                            {this.state.account.runCount}
                            <span className="ml-1 text-xl">
                              /{" "}
                              {this.state.account.runLimit === Infinity ? (
                                <span>&#8734;</span>
                              ) : (
                                this.state.account.runLimit
                              )}
                            </span>
                          </p>
                          <p className="text-sm text-tone-700">
                            Your count resets on {this.state.runResetDate}
                          </p>
                          {this.state.user?.org?.plan !== "partner" && (
                            <Link
                              to={this.state?.portalUrl || "/billing"}
                              className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group"
                            >
                              <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                              <span className="text-sm">Upgrade to increase your run limit</span>
                            </Link>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="relative flex flex-col items-center w-full gap-5 p-8 border border-tone-500 rounded-3xl">
                    {this.canCreateTemplate() || <div className="h-0"></div>}

                    
                    <h2 className="flex flex-col items-center gap-2 text-tone-900">
                      Add another template{" "}
                      {this.state.user?.org?.plan === "trial" && (
                        <div className="z-50 flex items-center gap-2 px-3 py-1 text-sm text-white transition-colors rounded-full bottom-2 left-2 bg-amber-500 opacity-90">
                          <SparklesIcon className="w-5 h-5 -ml-1" />
                          Starter + Pro
                        </div>
                      )}
                    </h2>
                    <div className="flex-1 min-w-[50%] group">
                      <label htmlFor="newTemplateName" className="mb-1">
                        Template Name
                      </label>
                      <input
                        id="newTemplateName"
                        className="w-full"
                        type="text"
                        defaultValue={`Template ${this.state.templates.length + 1}`}
                        onChange={(e) => this.setState({ newTemplateName: e.target.value })}
                      />
                    </div>
                    <div className="">
                      <button className="button-primary" onClick={(e) => this.createTemplate()}>
                        Add New Template
                      </button>
                    </div>
                    {this.canCreateTemplate() || (
                      <div className="absolute top-0 w-full h-full overflow-hidden l-0 rounded-3xl">
                        <svg
                          class="z-10 absolute inset-0 bg-white opacity-50 h-full w-full stroke-tone-900/10"
                          fill="none"
                        >
                          <defs>
                            <pattern
                              id="pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a"
                              x="0"
                              y="0"
                              width="10"
                              height="10"
                              patternUnits="userSpaceOnUse"
                            >
                              <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
                            </pattern>
                          </defs>
                          <rect
                            stroke="none"
                            fill="url(#pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a)"
                            width="100%"
                            height="100%"
                          ></rect>
                        </svg>
                        <Link
                          to="/billing"
                          className="absolute z-50 flex items-center gap-2 px-3 py-1 text-sm text-white transition-colors transform -translate-x-1/2 rounded-full hover:bg-amber-600 hover:text-white bg-amber-500 opacity-90 top-3 left-1/2"
                        >
                          <SparklesIcon className="w-5 h-5 -ml-1" />
                          Upgrade to unlock more templates
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="hidden lg:flex lg:basis-96"></div>
              </div>
            </motion.div>

            <Message open={this.state.loading} type="loading" title="Loading..." />
            <Modal
              open={this.state?.error}
              type="error"
              title={this.state.errorDetails.title}
              message={this.state.errorDetails.message}
              setClose={() => {
                this.setState({ error: false }),
                  setTimeout(() => this.setState({ errorDetails: false }), 500);
              }}
            />
          </>
        )}
      </>
    );
  }
}

export default TemplateList;
