import React, { useState, useEffect } from "react";
import { Redirect } from "@reach/router";
import Session from "./components/Session";
import LoadingState from "./components/states/LoadingState";

const PrivateRoute = ({ component: Component, ...props }) => {
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    async function getData() {
      const user = await Session.getUserData();  
      setLoggedIn(!!user);
    }

    getData();
  }, []);

  if (loggedIn === null) {
    return <LoadingState />
  }

  return (
    <div className="h-full">
      {loggedIn ? <Component {...props} /> : <Redirect to={`/login?redirect=${props.location.pathname}`} noThrow={true} />}
    </div>
  );
};

export default PrivateRoute;